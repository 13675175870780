<template>
  <div class="content" v-loading.fullscreen.lock="loading">
    <div class="title">CSDN文件下载</div>
    <div>
      <el-form
        label-position="top"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="卡密" prop="code">
          <el-input placeholder="卡密：16位，如bilivipxxxx"  v-model.trim="ruleForm.code"></el-input>
        </el-form-item>
        <el-form-item label="链接" prop="url">
          <el-input placeholder="下载或文章链接,如：https://download.csdm.net/xxxx" v-model.trim="ruleForm.url"></el-input>
        </el-form-item>
       
        <el-form-item>
          <div class="action-btn">
            <el-button type="primary" @click="submitForm('ruleForm')">下载</el-button>
          </div>

          <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
        </el-form-item>
        <p v-if="downUrl">提示：如果被浏览器拦截，可复制下面链接在浏览地址栏中打开下载。</p>
        <el-form-item v-if="downUrl"  title="可复制在浏览器地址栏中打开下载">
          <el-input placeholder="请输入内容" v-model="downUrl">
          <template slot="prepend">解析后的链接</template>
          <template slot="append">
            <span class="copy-btn" @click="copyText">复制</span>
          </template>
        </el-input>
          <!-- <el-input placeholder="可复制在浏览器地址栏中打开下载" v-model="downUrl"></el-input> -->
        </el-form-item>
        <div class="img">
            <p>提示：不能下载付费文件。</p>
        <div>
            <img src="@/static/img/1.png" alt="">
        </div>
        </div>
      
       
      </el-form>
    </div>
  </div>
</template>
  
  <script>
import axios from 'axios';

export default {
  name: 'VipDown',
  data() {
    return {
      loading: false,
      ruleForm: {
        url: '',
        code: '',
        adapter: 1,
        wrap: true
      },
      downUrl: '',
      rules: {
        url: [{ required: true, message: '请输入卡密', trigger: 'blur' }],
        code: [{ required: true, message: '请输入链接', trigger: 'blur' }],
      }
    };
  },

  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          // 调用接口

          if(this.ruleForm.url.includes('blog.csdn.net/')||this.ruleForm.url.includes('wenku.csdn.net/')){
            this.$message({
                  message: '你的链接填写错误,你填写的是cs博客链接，需要缓存或者下载解锁博客文章请到该店铺中购买超级会员',
                  type: 'error'
                });

            return

          }

          if(!this.ruleForm.url.includes('download.csdn.net/')){
            this.$message({
                  message: '你的链接填写错误,请填写下载文件资源链接',
                  type: 'error'
                });
            return
          }
          
      
          this.$message({
            message: '开始下载中...，请耐心等待，文件比较大就会久一点',
            type: 'success'
          });
          this.loading = true;
          axios
            .get('https://api.todo6.com/app/operate/cardonce/downCsdn', { params: { ...this.ruleForm } })
            .then(res => {
                // 不管成功还是失败都缓存
                localStorage.setItem('url',this.ruleForm.url)
              if (res.data.code == 1000) {
                this.$message({
                  message: '下载成功，请注意查收',
                  type: 'success'
                });

                let str = res.data.data.url;
                let lastIndex = str.lastIndexOf("/");
                let lastSegment = str.substring(lastIndex + 1);
                var beforeSlash = str.substring(0, lastIndex);

                this.downUrl = beforeSlash+'/'+encodeURIComponent(lastSegment);
                localStorage.setItem('url',this.ruleForm.url)

                let a = document.createElement('a'); //创建一个a标签元素
                a.style.display = 'none'; //设置元素不可见
                a.href = this.downUrl; //设置下载地址，http地址
                document.body.appendChild(a); //追加dom元素
                a.click(); //触发点击,下载
                document.body.removeChild(a); //删除dom元素
              } else {

                if(res.data.message.includes("latin-1' codec can't encode characters in") ){
                  this.$message({
                  message: '请正确填写文件资源下载链接',
                  type: 'error'
                });
                }else{
                  this.$message({
                  message: res.data.message,
                  type: 'error'
                });
                }
               
              }
              console.log('响应参数', res);
              this.loading = false;
            })
            .catch(() => {
              this.$message({
                message: '下载失败，请联系管理人员',
                type: 'error'
              });
              this.loading = false;
            });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    copyText() {
      this.$copyText(this.downUrl).then(() => {
        this.$message.success('复制成功');
      }).catch(() => {
        this.$message.error('复制失败');
      });
    }
    // 复制功能
    // copyInput(){

    // }
    // handleOpenDrawer() {
    //   this.$refs.refImageDrawer.openUrl('./img.jpeg');
    // },

    // handleSave(base64, file) {
    //   console.log(base64);
    //   console.log(file);
    // }
  },
  mounted() {
    const urlParams = window.location.search.substring(1).split('&');
    const paramsObject = urlParams.reduce((params, param) => {
      const [key, value] = param.split('=');
      params[key] = decodeURIComponent(value);
      return params;
    }, {});
    console.log(paramsObject);
    if (paramsObject && paramsObject.code) {
      this.ruleForm.code = paramsObject.code;
    }
    let downUrl= localStorage.getItem('url')
 if(downUrl){
    this.ruleForm.url=downUrl

 }
  }

 
};
</script>
  
  <style lang="less">
.content {
  width: 768px;
  margin: 22px auto;
  box-shadow: 1px 2px 10px 0px rgba(0, 0, 0, 0.5);
  padding: 30px;
  .title {
    text-align: center;
  }
}

.action-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img{
    width: 100%;
    margin-bottom: 10px;
    img{
        width: 100%;
    }
}
.copy-btn{
  cursor: pointer;
}
@media (max-width: 800px) {
  .content {
    width: calc(100% - 66px);
    /* margin: auto; */
  }
}
</style>
  